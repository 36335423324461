/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    img: "img",
    strong: "strong"
  }, _provideComponents(), props.components), {ImageMDX} = _components;
  if (!ImageMDX) _missingMdxReference("ImageMDX", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "How does a chicken breed develop which always shows the same characteristics and how are changes in the offspring possible?"), "\n", React.createElement(_components.p, null, "Elaborating on these questions, Gregor Mendel evolved his Mendelian Laws in the nineteenth century."), "\n", React.createElement(_components.p, null, "According to these laws, every species has a certain number of chromosomes. Every chromosome is present double (double set of chromosomes = 2n = diploid)."), "\n", React.createElement(_components.p, null, "There is, however, only a single set of chromosomes in the germinal cells of the parent animals (haploid)."), "\n", React.createElement(_components.p, null, "When the two haploid chromosome sets of the parent animals join, a new diploid set of chromosomes will develop in the offspring.\nThe chromosomes determine all characteristics and behavioral patterns of a living being."), "\n", React.createElement(_components.p, null, "The example below offers a simplified illustration of the chromosome for black feathers."), "\n", React.createElement(_components.h2, null, "Intermediary heredity"), "\n", React.createElement(ImageMDX, {
    align: "right"
  }, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/960b9caef662dddc97c17bf592b65342/inter.gif",
    alt: ""
  }))), "\n", React.createElement(_components.p, null, "In intermediary heredity the mother is a white homozygote and the father is a black homozygote, i.e. the mother has two identical genes for the white and the father has two identical genes for the black color of the feathers. During fertilization, each parent gives away one gene."), "\n", React.createElement(_components.p, null, "All young animals will then have one black gene and one white gene. Genotype (genetic disposition) and phenotype (outer appearance) will be uniform."), "\n", React.createElement(_components.p, null, "The offspring will, therefore, be gray."), "\n", React.createElement(_components.p, null, "In the next generation, there will be two heterozygous parent animals, i.e. they will black-white both with regard to genotype and phenotype."), "\n", React.createElement(_components.p, null, "Now, their offspring will be each 25% black and white homozygotes because two black or white genes, respectively, were transmitted in this case. 50 % of the offspring will be heterozygotes because they received one black and one white gene."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Conclusion:"), " Black and white genes are passed on in the same ratio."), "\n", React.createElement(_components.h2, null, "Dominant-recessive hereditary"), "\n", React.createElement(ImageMDX, {
    align: "right"
  }, React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/6e42929ce49f7c2b25cd950c08b75351/dom-rez.gif",
    alt: ""
  }))), "\n", React.createElement(_components.p, null, "In the dominant-recessive hereditary course, the parent animals are also black and white homozygotes."), "\n", React.createElement(_components.p, null, "Again, the young animals will have both a black and a white gene. Nevertheless, they will be phenotypically black. This means that black is predominant in the dominant-recessive hereditary course if black and white is present genotypically."), "\n", React.createElement(_components.p, null, "So the black gene is dominant and the white gene is recessive."), "\n", React.createElement(_components.p, null, "The offspring of these genotypical heterozygotes that are phenotypically black will be each 25% black and white homozygotes because two black or white genes, respectively, were transmitted."), "\n", React.createElement(_components.p, null, "50% of the offspring will be genotypical heterozygotes, but they will be phenotypically black since black is the dominant gene."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Conclusion:"), " If an animal has two black genes it will be phenotypically black. If an animal has one black and one white gene it will also be black because black is dominant and, therefore, influences the phenotype. White animals can only originate if both genes are white."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
